// App.js
import React from 'react';
import Login from './LoginPage'; // Login'ı default olarak import ediyoruz

const App = () => {
    return (
        <div>
            <Login /> {/* Login bileşenini kullanıyoruz */}
        </div>
    );
};

export default App;
