import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';

const Login = () => {
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [vergiDaireleri, setVergiDaireleri] = useState([]);
    const [token, setToken] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [newUser, setNewUser] = useState({
        ad: '',
        ticaretSicilNo: '',
        kimlikNo: '',
        vergiDaireId: ''
    });
    const [selectedUser, setSelectedUser] = useState(null);


    useEffect(() => {
        const manualToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IkE2OEIyNjhFNDEyQjYyRjQ0ODMzNDA4QjkxREQ2OTE5QzA3RTcwRTgiLCJ4NXQiOiJwb3NtamtFcll2UklNMENMa2QxcEdjQi1jT2ciLCJ0eXAiOiJhdCtqd3QifQ.eyJpc3MiOiJodHRwczovL2F1dGhjcm0uYWRhbHlhbW90b3IuY29tLyIsImV4cCI6MTczMTc1Nzk0NywiaWF0IjoxNzMxNzU0MzQ3LCJhdWQiOiJNb3RvQ3JtIiwic2NvcGUiOiJNb3RvQ3JtIiwianRpIjoiNzk5YWNiMTEtZjUyMC00YWVmLWE4MTgtMzczMDZhNGMxOTdiIiwic3ViIjoiM2ExMjNjOGEtY2E0Ni0xOTVlLTIxZTktN2ViYzFhZTY2NWQ4IiwidW5pcXVlX25hbWUiOiJhZG1pbiIsIm9pX3Byc3QiOiJNb3RvQ3JtX1N3YWdnZXIiLCJvaV9hdV9pZCI6IjNhMTYyMWY4LWViNjItMGU3ZS03MWJhLWIwZmRlM2Y0ZDk2OCIsInByZWZlcnJlZF91c2VybmFtZSI6ImFkbWluIiwiZ2l2ZW5fbmFtZSI6ImFkbWluIiwiZmFtaWx5X25hbWUiOiJ0b3DDp3UiLCJyb2xlIjoiYWRtaW4iLCJlbWFpbCI6ImFkbWluQGFicC5pbyIsImVtYWlsX3ZlcmlmaWVkIjoiRmFsc2UiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOiJGYWxzZSIsImNsaWVudF9pZCI6Ik1vdG9Dcm1fU3dhZ2dlciIsIm9pX3Rrbl9pZCI6IjNhMTY0NmM3LTI0YTYtZDQ3OC00YWM2LWM1NjM3MWVkYzZiOSJ9.ysHFiq3W_mmPXNhFK2AOcc_gpnbuBXYS6I2WrwNnBUT4IWRxY52Ltr7i1XFlqKJpDnm6wascgKyAKNUxKnPUqyDvVNAjvWqOzCn-jZMH-V4I19tQggaMPtgOi_yWMvM1EVtg4uplQ_Wy0EQhgBOKl0xJpJzcnR0WMFT1qQ8ibEIwjotT67S1HpyhBev_GffbjgpCEkCQbifanPyJuuLrZYFA0PocZshi7vd5CzTmeR2rop-bbUxR6X7YticC7otgApMAL53IjGYGR1LYqBePF2iaEhth1sRKEjkGGS3S0N-oGxDlDz9orPnSHxCqv5M4LvcFYoDo5XWU-oFQcNxJSA';
        setToken(manualToken);
    }, []);

    const fetchVergiDaireleri = useCallback(async () => {
        try {
            const response = await axios.get('https://apicrm.adalyamotor.com/api/app/vergi-daire', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setVergiDaireleri(response.data.items);
        } catch (error) {
            setError('Vergi daireleri alınırken bir hata oluştu.');
            console.error(error);
        }
    }, [token]);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.get('https://apicrm.adalyamotor.com/api/app/kisi', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setUsers(response.data.items);
        } catch (error) {
            setError('Kullanıcı verileri alınırken bir hata oluştu.');
            console.error(error);
        }
    }, [token]);

    const handleAddUser = async (e) => {
        e.preventDefault(); 
        if (!newUser.ad || !newUser.ticaretSicilNo || !newUser.kimlikNo || !newUser.vergiDaireId) {
            setError('Lütfen tüm alanları doldurduğunuzdan emin olun.');
            return;
        }

        try {
            const response = await axios.post('https://apicrm.adalyamotor.com/api/app/kisi', newUser, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response && response.data) {
                setUsers([...users, response.data]);
                setShowModal(false);
                setNewUser({ ad: '', ticaretSicilNo: '', kimlikNo: '', vergiDaireId: '' });
            } else {
                setError('Beklenmedik bir yanıt alındı.');
            }
        } catch (error) {
            setError('Kişi eklenirken bir hata oluştu.');
            console.error(error);
        }
    };
    // URL parametrelerini kaldır
    useEffect(() => {
        if (window.location.search) {
            window.history.replaceState(null, '', window.location.pathname); 
        }
    }, []);  


    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`https://apicrm.adalyamotor.com/api/app/kisi/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setUsers(users.filter(user => user.id !== userId));
            alert('Kişi başarıyla silindi.');
        } catch (error) {
            setError('Kişi silinirken bir hata oluştu.');
            console.error(error);
        }
    };

    const handleUpdateUser = async () => {
        if (!selectedUser) return;

        try {
            const response = await axios.put(`https://apicrm.adalyamotor.com/api/app/kisi/${selectedUser.id}`, selectedUser, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const updatedUsers = users.map(user =>
                user.id === selectedUser.id ? response.data : user
            );
            setUsers(updatedUsers);
            setShowUpdateModal(false);
            setSelectedUser(null);
        } catch (error) {
            setError('Kişi güncellenirken bir hata oluştu.');
            console.error(error);
        }
    };

    useEffect(() => {
        if (token) {
            fetchVergiDaireleri();
            fetchUsers();
        }
    }, [token, fetchVergiDaireleri, fetchUsers]);

    const getVergiDaireAdi = (vergiDaireId) => {
        const vergiDaire = vergiDaireleri.find(item => item.id === vergiDaireId);
        return vergiDaire ? vergiDaire.ad : 'Veri bulunamadı';
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
    };

    return (
        <div className="table-responsive">
            <div>
                <h2>Kişiler</h2>
                {error && <p>{error}</p>}
                <button onClick={() => setShowModal(true)}>Kişi Ekle</button>
            </div>
            <table >
                <thead>
                    <tr>
                        <th>Ad</th>
                        <th>Ticaret Sicil No</th>
                        <th>Kimlik No</th>
                        <th>Vergi Dairesi</th>
                        <th>Oluşturulma Tarihi</th>
                        <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={index}>
                            <td data-label="Ad Soyad:">{user.ad}</td>
                            <td data-label="Ticari Sicil No:">{user.ticaretSicilNo}</td>
                            <td data-label="TC Kimlik:">{user.kimlikNo}</td>
                            <td data-label="Vergi Dairesi:">{getVergiDaireAdi(user.vergiDaireId)}</td>
                            <td data-label="Oluşturulma Tarihi:">
                                {new Date(user.creationTime).toLocaleString('tr-TR', {
                                    timeZone: 'Europe/Istanbul',
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false,
                                })}
                            </td>
                            <td>
                                <button onClick={() => {
                                    setSelectedUser(user);
                                    setShowUpdateModal(true);
                                }}>Güncelle</button>
                                &nbsp;
                                <button onClick={() => handleDeleteUser(user.id)}>Sil</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
   
         

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <h2>Kişi Ekle</h2>
                        <form onSubmit={handleAddUser} className="modal-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="ad"
                                    value={newUser.ad}
                                    onChange={(e) => setNewUser({ ...newUser, ad: e.target.value })}
                                    placeholder="Ad"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="ticaretSicilNo"
                                    value={newUser.ticaretSicilNo}
                                    onChange={(e) => setNewUser({ ...newUser, ticaretSicilNo: e.target.value })}
                                    placeholder="Ticaret Sicil No"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="kimlikNo"
                                    value={newUser.kimlikNo}
                                    onChange={(e) => setNewUser({ ...newUser, kimlikNo: e.target.value })}
                                    placeholder="Kimlik No"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <select
                                    name="vergiDaireId"
                                    value={newUser.vergiDaireId}
                                    onChange={(e) => setNewUser({ ...newUser, vergiDaireId: e.target.value })}
                                    required
                                >
                                    <option value="">Vergi Dairesi Seç</option>
                                    {vergiDaireleri.map((vergiDaire) => (
                                        <option key={vergiDaire.id} value={vergiDaire.id}>
                                            {vergiDaire.ad}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit" className="submit-button">Ekle</button>
                        </form>
                    </div>
                </div>
            )}


            {showUpdateModal && selectedUser && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowUpdateModal(false)}>&times;</span>
                        <h2>Kişi Güncelle</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleUpdateUser(); }}>
                            <input
                                type="text"
                                name="ad"
                                value={selectedUser.ad}
                                onChange={handleInputChange}
                                placeholder="Ad"
                                required
                            />
                            <input
                                type="text"
                                name="ticaretSicilNo"
                                value={selectedUser.ticaretSicilNo}
                                onChange={handleInputChange}
                                placeholder="Ticaret Sicil No"
                                required
                            />
                            <input
                                type="text"
                                name="kimlikNo"
                                value={selectedUser.kimlikNo}
                                onChange={handleInputChange}
                                placeholder="Kimlik No"
                                required
                            />
                            <select
                                name="vergiDaireId"
                                value={selectedUser.vergiDaireId}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Vergi Dairesi Seç</option>
                                {vergiDaireleri.map((vergiDaire) => (
                                    <option key={vergiDaire.id} value={vergiDaire.id}>
                                        {vergiDaire.ad}
                                    </option>
                                ))}
                            </select>
                            <button type="submit" className="submit-button">Güncelle</button>
                        </form>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Login;
